<template>
  <el-row class="form" :data-step="2">
    <el-col :xs="24"
            :sm="24"
            :md="18"
            :lg="16"
            :xl="14"
            >
      <el-input class="inputWid"
                v-model="form.searchKey"
                clearable
                @keyup.enter.native="confirm"
                placeholder="请输入区块高度、区块哈希、账本哈希、链上账户查询数据">

        <el-select class="searchSelect"
                   @change="handleSelect"
                   slot="prepend"
                   v-model="form.shard">
          <el-option v-for="item in options"
                     :key="item.shard"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>

        <el-button class="search-btn"
                   slot="append"
                   icon="el-icon-search"
                   @click="confirm">查询</el-button>

      </el-input>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: []
    }
  },
  mounted() {
    this.getAliases()
  },
  methods: {
    // 分片名切换刷新
    handleSelect() {
      this.$emit('select', this.form.shard)
    },
    // 分片名数组
    async getAliases() {
      let { data } = await this.$api.home.shard()
      if (data) {
        let array = [
          {
            value: null,
            label: '全部'
          }
        ]
        for (let item of data) {
          let obj = {}
          obj.value = item
          obj.label = item
          array.push(obj)
        }
        this.options = array
      }
    },
    confirm() {
      let _form = {
        shard: this.form.shard,
        searchKey: this.form.searchKey ? this.trim(this.form.searchKey) : ''
      }
      // 判断是否跳转详情页（带上分片与搜索字段）
      if (_form.searchKey) {
        this.$router.push({
          name: 'chainDataDetails',
          query: {
            searchKey: _form.searchKey
          }
        })
        // 触发查询
        this.$emit('search', _form)
      }
    },
    trim(s) {
      // 去除目标字符串左右两边的空白字符
      return s.replace(/(^\s*)|(\s*$)/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.el-input-group__prepend{
  border: 0;
}
.form {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .searchSelect {
    position: relative;
    top: 0;
    left: 80%;
    transform: translate(-50%);
    min-width: 120px !important;
    background: #f5f5f5;
    color: #525252;
    border-right: 1px solid #c2c2c2;
  }
  .inputWid {
    height: 44.44px !important;
    border: 2px solid $--color-primary;
    border-radius: 0.2rem;
    color: #525252;
  }
  ::v-deep.searchSelect .el-input__inner {
    text-align: center !important;
    height: 30px !important;
  }
  ::v-deep.el-input__inner {
    border: 0 !important;
    background: #f5f5f5 !important;
  }
  .search-btn {
    width: 120px !important;
    height: 44px !important;
    position: relative;
    left: 2px;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    // background: #fe8b56;
    background: $--color-primary;
    color: white;
  }
  .search-btn:active{
    background: #183269;
  }
}
</style>
